import _environmentInterface from '@server/shared/environments/environment.interface'

// DO NOT USE
// this file replaces environment.ts during build
const _environment = new (class implements _environmentInterface {
  isProduction = true
  isServer = false
  appSecret = ''
})()

export default _environment
