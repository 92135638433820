import environment from '@server/shared/environments/environment'

export default new (class {
  appSecret = environment.appSecret

  isServer = environment.isServer
  isProduction = environment.isProduction

  api = new (class {
    constructor(private isProduction: boolean) {}
    trpc = 'http://localhost:4200/api/trpc'
    rest = 'http://localhost:4200/api/rest'
    play = 'http://localhost:4200/api/play'
  })(this.isProduction)

  auth = new (class {
    jwtKey = 'mt-jwt'
    authUserKey = 'mt-auth-user'
    passwordRetryLimit = 5
    jwtRememberDays = 14 // days to store if remember me is checked
    jwtTempHours = 12 // hours to store if remember me is not checked
  })()
})()
